module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://sukar.app"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/home/","/privacy/","/mobile-eula/"],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sukar","short_name":"Sukar","start_url":"/","background_color":"#ffffff","theme_color":"#677AFF","display":"standalone","icons":[{"src":"icons/48.png","sizes":"48x48","type":"image/png"},{"src":"icons/72.png","sizes":"72x72","type":"image/png"},{"src":"icons/100.png","sizes":"100x100","type":"image/png"},{"src":"icons/144.png","sizes":"144x144","type":"image/png"},{"src":"icons/196.png","sizes":"196x196","type":"image/png"},{"src":"icons/256.png","sizes":"256x256","type":"image/png"},{"src":"icons/512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162819673-1","head":false,"pageTransitionDelay":0,"defer":true},
    }]
